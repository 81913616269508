import Vue from "vue";
import VueRouter from "vue-router";

const Resources = () => import("@/pages/Resources");
const Assets = () => import("@/pages/Assets");
const Asset = () => import("@/pages/Asset");
const JetbrainsAsset = () => import("@/pages/JetbrainsAsset");
const Library = () => import("@/pages/Library");
const SupportRequests = () => import("@/pages/SupportRequests");
const Users = () => import("@/pages/Users");
const User = () => import("@/pages/User");
const Offices = () => import("@/pages/Offices");
const OfficeGroups = () => import("@/pages/OfficeGroups");
const Dashboard = () => import("@/pages/Dashboard");
const Keys = () => import("@/pages/Keys");
const Offboard = () => import("@/pages/Offboard");
const Retailers = () => import("@/pages/Retailers");
const QrAsset = () => import("@/pages/QrAsset");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "Assets" },
  },
  {
    path: "/resources",
    name: "Resources",
    component: Resources,
  },
  {
    path: "/assets",
    name: "Assets",
    component: Assets,
  },
  {
    path: "/library",
    name: "Library",
    component: Library,
  },
  {
    path: "/keys",
    name: "Keys",
    component: Keys,
  },
  {
    path: "/asset/:assetId",
    name: "Asset",
    component: Asset,
    props: true,
  },
  {
    path: "/jetbrainsAsset/:id",
    name: "JetbrainsAsset",
    component: JetbrainsAsset,
    props: true,
  },
  {
    path: "/assets/:uuid",
    name: "AssetUUID",
    component: Assets,
    props: true,
  },
  {
    path: "/asset/qr/:uuid",
    name: "QrAsset",
    component: QrAsset,
    props: true,
  },
  {
    path: "/support-requests",
    name: "SupportRequests",
    component: SupportRequests,
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
  },
  {
    path: "/users/:userId",
    name: "User",
    component: User,
    props: true,
  },
  {
    path: "/users/:userId/offboard",
    name: "Offboard",
    component: Offboard,
    props: true,
  },
  {
    path: "/offices",
    name: "Offices",
    component: Offices,
  },
  {
    path: "/officeGroups",
    name: "OfficeGroups",
    component: OfficeGroups,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/retailers",
    name: "Retailers",
    component: Retailers,
    props: true,
  },
];

const router = new VueRouter({ routes });

export default router;
